import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3><a parentName="h3" {...{
        "href": "https://crossfittheville.files.wordpress.com/2011/02/imagescaa4r7lr.jpg"
      }}><img parentName="a" {...{
          "src": "https://crossfittheville.files.wordpress.com/2011/02/imagescaa4r7lr.jpg?w=615",
          "alt": null,
          "title": "imagesCAA4R7LR"
        }}></img>{`{.alignnone .size-full .wp-image-50}`}</a></h3>
    <h3>{`1.    Save time working out`}</h3>
    <p>{`Why spend 1-2 hours at the gym, when you can get a great workout in less
than 30 minutes.  Don’t get me wrong, not ALL of the workouts are that
short, but a good portion of them are.`}</p>
    <h3>{`2.    Get stronger physically AND mentally`}</h3>
    <p>{`You will gain (or regain) strength to allow you to lift large loads over
long distances quickly. Whether you’re moving a large 300lb TV or
picking up a little child off the floor, you will become stronger. The
workouts will also help you build mental fortitude. When you complete a
once seemingly difficult workout, you get a sense of acomplishment that
will help you conquer other obstacles in your life.`}</p>
    <p>{`Yes, you can experience the full benefit of these two traits from most
workout regimens.  However, with CrossFit you get a large support group
that will help you throughout your progression.  The community is very
tight knit and we all champion each other.  It’s very similar to “Team
in Training” for distance runners.  The collaboriative support of each
individual member is what makes the program such a success. This goes
into my next point…`}</p>
    <h3>{`3.    Make friends from all walks of life`}</h3>
    <p>{`The CrossFit community is broad and inclusive. You may come across your
local police officers, firefighters, and even your first grade teacher.
CrossFit gyms are not like your local Globo Gyms where everyone is out
there for themselves.  They training environments are much more quaint
allowing you to build strong relationships full of integrity and
comraderie.`}</p>
    <h3>{`4.    CrossFit is safe`}</h3>
    <p>{`Safety and efficiency are the most important and interdependent elements
of any fitness program. In order for a fitness program to be 100% safe,
it must be 100% ineffective (ie. 60 minutes of the elliptical). The
intensity involved with CrossFit are only introduced once proper
technique and mechanics are established.`}</p>
    <h3>{`5.    Functional movements`}</h3>
    <p>{`How long do you want to be able to get in and out of bed on your own?
How about go to the bathroom on your own? If you’re like me, you answer
is pretty much “As long as I’m alive!“. These are natural and essential
movements that all humans should be able to do. These cannot be broken
down into simple movements done on the leg extension or bicep curl
machines.`}</p>
    <h3>{`6.    Prepare for the Unknown & Unknowable`}</h3>
    <p>{`Who knows when you will need to save your own or your family’s life. In
the unlikely event of a fire or natural disaster, don’t you want to be
ready and able to do what you need to do? Call me crazy but often times
I ask myself if I can outrun a cop if I were to break the law if I lived
in an alternate reality.  The thing is, I probably can.`}</p>
    <h3>{`7.    Never get bored`}</h3>
    <p>{`Begone boring workout routines. Yeah, I admit it… I used to do the
whole routine of back/biceps on Monday, chest/triceps on Wednesday, and
legs/shoulders on Friday EVERY WEEK. I know some of you have too. You
will never do what you did last week or even last month. The best way to
stick to a fitness program is to keep it dynamic and interesting. 
Because the CrossFit regimen follows an open source model, you’ll find
adaptations and variations all the time that target whatever your focus
is on that day.`}</p>
    <p>{`HOME WOD`}</p>
    <p>{`100 Sit-ups`}</p>
    <p>{`5 min plank`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      